html {
  background-color: var(--color-black);
}

body {
  margin: 0;
  overflow: hidden;
  color: var(--color-white);
  font-family: 'Blender Pro';
  background-color: var(--color-black);

  .loader {
    position: absolute;
    left: 50vw;
    top: 50vh;
    transform: translate(-50%, -50%);
    z-index: 999;
    text-transform: uppercase;
    font-size: 20px;
    margin: 0;
    pointer-events: none;
    opacity: 0;
  }

  > * {
    transition: opacity 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &.waiting > * {
    opacity: 0;
    &.loader { opacity: .5; }
  }

  .ui {
    header {
      position: absolute;
      top: 0; left: 0; right: 0;
      z-index: 99;

      h1 {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 400;
        margin: 16px;
        line-height: 1;

        b {
          font-weight: 800;
        }
      }
    }

    #citizen-info {
      position: absolute;
      top: 15%; left: 80%;
      z-index: 98;
      opacity: 0;
      transition: 1s cubic-bezier(0.215, 0.610, 0.355, 1);

      &.show {
        opacity: 1;
        left: 67%;
      }

      &::before {
        content: '';
        border-radius: 10px;
        position: absolute;
        top: 2px;
        left: -38px;
        width: 20px;
        height: 20px;
        background-color: var(--selected-color);
      }

      div {
        margin-bottom: 16px;

        p {
          margin: 0;
          font-size: 20px;
          
          b {
            text-transform: uppercase;
            font-weight: 700;
          }

          .status {
            color: var(--selected-color);
            text-transform: uppercase
          }

          span {
            font-weight: 200;
          }
        }
      }

      .track {
        color: currentColor;
        font-size: 20px;
        background: none;
        border: none;
        font-family: 'Blender Pro';
        text-transform: uppercase;
        padding: 0;
        display: flex;
        align-items: center;
        cursor: pointer;

        i {
          margin-right: 10px;
          font-size: 20px;
        }
      }
    }
  }

  main {
    background-color: var(--color-black);
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;

    #wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform-origin: center;
      transition: 1s cubic-bezier(0.215, 0.610, 0.355, 1);

      --scale: 0.2;
      --translateX: -50%;
      transform: translate(var(--translateX), -50%) scale(var(--scale));

      canvas {
        position: absolute;
      }

      #actions {
        position: absolute;
        z-index: 10;

        .citizen {
          position: absolute;
          width: 90px;
          height: 90px;
          border-radius: 45px;
          border: 6px solid transparent;
          cursor: pointer;
          background: none;
          z-index: 10;
          color: var(--self-anti-color);

          i {
            font-size: 30px;
            pointer-events: none;
            vertical-align: -5px;
          }

          &:hover, &.active {
            border-color: var(--self-color);;
          }
        }
        
        .node {
          position: absolute;
          width: 60px;
          height: 60px;
          border: none;
          border: 6px solid transparent;
          cursor: pointer;
          background: none;
          display: none;
          z-index: 8;

          &.show {
            display: block;
          }

          &:hover {
            border-color: var(--color-white);
          }
        }

        .token-icon {
          pointer-events: none;
          position: absolute;
          top: -30px;
          left: -30px;
          color: var(--color-green);
          font-size: 60px
        }
      }
    }
  }
}
